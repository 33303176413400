import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-vmusium'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/configuration/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
      let allRows = []
      allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center' },
          { text: vm.$t('tour_management.request_id'), style: 'th', alignment: 'center' },
          { text: vm.$t('pp_visit_request.request_type'), style: 'th', alignment: 'center' },
          { text: vm.$t('tour_management.contact_person'), style: 'th', alignment: 'center' },
          { text: vm.$t('tour_management.mobile_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('tour_management.number_of_visitor'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' },
          { text: vm.$t('pp_visit_request.notes'), style: 'th', alignment: 'center' }
          // { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' }
        ]
      ]
      // table body
      if (vm.$store.state.list.length) {
        vm.$store.state.list.filter((item, index) => {
        //    let status = 'inactive'
        //    if (item.status === 2) {
        //      status = 'active'
        //    }
           const rowItem = [
             { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.org_name : item.org_name_bn, style: 'td', alignment: 'center' },
             { text: item.request_id, style: 'td', alignment: 'center' },
             { text: i18n.locale === 'en' ? item.request_type : item.request_type_bn, style: 'td', alignment: 'center' },
             { text: item.contact_person_name, style: 'td', alignment: 'center' },
             { text: item.mobile_no, style: 'td', alignment: 'center' },
             { text: item.number_of_visitor, style: 'td', alignment: 'center' },
             { text: item.new_status, style: 'td', alignment: 'center' },
             { text: item.notes, style: 'td', alignment: 'center' }
            //  { text: item.status === 1 ? vm.$t('globalTrans.pending') : (item.status === 2 ? vm.$t('globalTrans.reject') : vm.$t('globalTrans.approved')), style: 'td', alignment: 'center' }
           ]
           allRows.push(rowItem)
        })
      } else {
        const rowItem = [
          { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: 7 },
          { },
          { },
          { },
          { },
          { },
          { },
          { },
          { }
        ]
        allRows.push(rowItem)
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['8%', '15%', '10%', '10%', '10%', '15%', '8%', '15%', '9%'],
          body: allRows
        }
      })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 11,
            bold: true,
            margin: [1, 1, 1, 1]
          },
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 14,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          td: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
