<template>
    <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.details')}}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loader">
                <b-row>
                    <b-col md="12" class="table-responsive">
                        <b-table-simple striped bordered small>
                            <b-tr>
                                <b-th>{{$t('globalTrans.organization')}} </b-th>
                                <b-td>{{ (localLan === 'bn') ? vRequest.org_name_bn : vRequest.org_name  }} </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('tour_management.visit_request_id')}}</b-th>
                                <b-td>{{ vRequest.request_id }}</b-td>
                            </b-tr>
                             <b-tr>
                                <b-th>{{ $t('pp_visit_request.request_type')}}</b-th>
                                <b-td>{{  (localLan === 'bn') ? vRequest.request_type_bn : vRequest.request_type }}</b-td>
                            </b-tr>
                             <b-tr>
                                <b-th>{{ $t('pp_visit_request.institute_name')}}</b-th>
                                <b-td>{{ vRequest.institute_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.contact_person_name')}}</b-th>
                                <b-td>{{ vRequest.contact_person_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.nid_no')}}</b-th>
                                <b-td>{{ vRequest.nid_no }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.mobile_no')}}</b-th>
                                <b-td>{{ vRequest.mobile_no }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.number_of_visitor')}}</b-th>
                                <b-td>{{ vRequest.number_of_visitor }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.email')}}</b-th>
                                <b-td>{{ vRequest.email }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('tour_management.requested_visit_date')}}</b-th>
                                <b-td>{{ vRequest.visit_date }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.time_slot')}}</b-th>
                                <b-td>{{ vRequest.time_slot }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.remarks')}}</b-th>
                                <b-td>{{ vRequest.remarks }}</b-td>
                            </b-tr>
                        </b-table-simple>
                             <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                    </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    </b-container>
</template>

<script>
// import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
// import { insectManageDetailsApi } from '../../api/routes'
// import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  // mixins: [ModalBaseMasterList],
  components: {
    },
  props: ['item'],
  data () {
    return {
      vRequest: {},
      loader: false,
      localLan: this.$i18n.locale,
      itemId: 0,
      requestId: 0,
      orgId: 0
    }
  },
  created () {
    this.vRequest = this.item
  },
  methods: {
  }
}
</script>
<style scoped>
.padding_left {
 padding-left: 15px
}
</style>
