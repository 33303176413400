
<template>
  <b-container fluid>
         <!-- Search Form -->
        <iq-card>
          <!-- Add New -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('tour_management.reports') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <!-- /Add New -->
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('globalTrans.organization')"
                  label-for="org_id"
                >
                    <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      :disabled="search.org_id != ''"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('pp_visit_request.request_type')"
                  label-for="request_type"
                >
                    <b-form-select
                      plain
                      v-model="search.request_type"
                      :options="requestTypeListInput"
                      id="request_type"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="application_id"
                  >
                      <template v-slot:label>
                      {{ $t('tour_management.application_id') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                      v-model="search.application_id"
                      :placeholder="$t('tour_management.application_id')"
                      ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('globalTrans.status')"
                  label-for="status"
                >
                    <b-form-select
                      plain
                      v-model="search.status"
                      :options="statusList"
                      id="status"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="visit_date_from"
                  >
                      <template v-slot:label>
                      {{ $t('tour_management.visit_date_from') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                      class="visit_date_to"
                      id="visit_date_to"
                      v-model="search.visit_date_from"
                      :placeholder="$t('tour_management.visit_date_from')"
                      ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="visit_date_to"
                  >
                      <template v-slot:label>
                      {{ $t('tour_management.visit_date_to') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                      class="visit_date_to"
                      id="visit_date_to"
                      v-model="search.visit_date_to"
                      :placeholder="$t('tour_management.visit_date_to')"
                      ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="12" class="text-right">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
              </b-col>
            </b-row>
 </template>
    </iq-card>
<!-- /Search Form -->
  <b-row v-show="showData">
      <b-col md="12">
         <iq-card>
            <!-- ================New Start=============== -->
            <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('tour_management.tour_management_report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                    <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
                </b-button>
            </template>
            <!-- ================New End================= -->
         <template v-slot:body>
          <b-overlay :show="loadingState">
          <!-- =============New Start=============== -->
                <b-row>
                    <b-col>
                        <list-report-head :base-url="virtualMuseumServiceBaseUrl"
                                          uri="configuration/report-heading/detail"
                                          :org-id="search.org_id"
                                          :key="reloadListHeading"
                                          :loading-state="loadingState">
                          {{ $t('tour_management.reports')}}
                        </list-report-head>
                    </b-col>
                </b-row>
          <!-- =============New End================= -->
              <b-row>
                <b-col md="12" class="table-responsive">
                  <!-- Table -->
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>

                    <template v-slot:cell(status)="data">
                          {{ getStatus(data.item.status) }}
                    </template>

                    <template v-slot:cell(number_of_visitor)="data">
                          {{ $n(data.item.number_of_visitor, { useGrouping: false }) }}
                    </template>

                    <template v-slot:cell(action)="data">
                     <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                  </b-table>

                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
           </template>
        </iq-card>
   </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('pp_visit_request.visit_request')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="detailItem" :key="itemId"></Details>
    </b-modal>

  </b-container>
</template>
<script>
// import Form from './Form'
import Details from './Details'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
// import { phyVisitAdminReportApi } from '../../api/routes'
import { phyVisitReqListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import flatpickr from 'flatpickr'
import ExportPdf from './export_pdf_details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
     Details,
     ListReportHead
  },
  data () {
    return {
      showData: false,
      search: {
        org_id: this.$store.state.dataFilters.orgId ? this.$store.state.dataFilters.orgId : 0,
        request_type: 0,
        application_id: '',
        status: 0,
        visit_date_from: '',
        visit_date_to: ''
      },
      editItemId: 0,
      itemId: 0,
      // rows: [],
      virtualMuseumServiceBaseUrl: virtualMuseumServiceBaseUrl,
      detailItem: '',
      reportHead: null,
      reloadListHeading: 1
    }
  },
  computed: {
    listReload () {
            return this.$store.state.commonObj.listReload
        },
        loadingState () {
            if (this.listReload) {
                return true
            } else if (this.loading) {
                return true
            } else {
                return false
            }
        },
    formTitle () {
        if (this.editItemId === 0) {
            return this.$t('pp_visit_request.visit_request') + ' ' + this.$t('globalTrans.entry')
        } else {
            return this.$t('pp_visit_request.visit_request') + ' ' + this.$t('globalTrans.modify')
        }
    },
    orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
        },
    requestTypeList: function () {
        const list = [
        { value: 1, text_en: 'Individual', text_bn: 'স্বতন্ত্র' },
        { value: 2, text_en: 'Institutional', text_bn: 'প্রাতিষ্ঠানিক' }
        ]
        return list
    },
    requestTypeListInput: function () {
        const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Individual' : 'স্বতন্ত্র' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Institutional' : 'প্রাতিষ্ঠানিক' }
        ]
        return list
    },
    // statusList: function () {
    //     const list = [
    //     { value: 1, text_en: 'Pending', text_bn: 'বিচারাধীন' },
    //     { value: 2, text_en: 'Reviewed', text_bn: 'পর্যালোচনা করা হয়েছে' },
    //     { value: 3, text_en: 'Approved', text_bn: 'অনুমোদিত' },
    //     { value: 4, text_en: 'Visited', text_bn: 'পরিদর্শন' },
    //     { value: 5, text_en: 'Reject', text_bn: 'প্রত্যাখ্যান করুন' }
    //     ]
    //     return list
    // },
    statusList: function () {
        const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'বিচারাধীন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reviewed' : 'পর্যালোচনা করা হয়েছে' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Visited' : 'পরিদর্শন' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Reject' : 'প্রত্যাখ্যান করুন' }
        ]
        return list
    },
    columns () {
      // Table Column Labels
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('tour_management.request_id'), class: 'text-center' },
          { label: this.$t('pp_visit_request.request_type'), class: 'text-center' },
          { label: this.$t('tour_management.contact_person'), class: 'text-center' },
          { label: this.$t('tour_management.mobile_no'), class: 'text-center' },
          { label: this.$t('tour_management.number_of_visitor'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('pp_visit_request.notes'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'request_id' },
          { key: 'request_type_bn' },
          { key: 'contact_person_name' },
          { key: 'mobile_no' },
          { key: 'number_of_visitor' },
          { key: 'status' },
          { key: 'notes' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'request_id' },
          { key: 'request_type' },
          { key: 'contact_person_name' },
          { key: 'mobile_no' },
          { key: 'number_of_visitor' },
          { key: 'status' },
          { key: 'notes' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  mounted () {
    flatpickr('#visit_date_from', {})
    flatpickr('#visit_date_to', {})
  },
  created () {
    // this.loadData()
  },
  watch: {
  },
  methods: {
    details (item) {
      this.detailItem = item
      this.itemId = item.id
    },
    pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Virtual Musium Applications' : 'প্রশিক্ষণ অ্যাপ্লিকেশন'
        ExportPdf.exportPdfDetails(virtualMuseumServiceBaseUrl, '/configuration/report-heading/detail', this.search.org_id, reportTitle, this)
      },
    searchData () {
    //   this.loadData()
            this.reloadListHeading++
            if (this.search.org_id !== 0) {
                this.loadData()
            } else {
                this.showData = false
            }
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      // RestApi.getData(virtualMuseumServiceBaseUrl, phyVisitAdminReportApi, params).then(response => {
      RestApi.getData(virtualMuseumServiceBaseUrl, phyVisitReqListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
            if (data.length !== 0) {
                this.showData = true
            }
            let orgList = {}
            const listData = data.map(item => {
                orgList = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.org_id)
               const rqType = this.requestTypeList.find(rql => rql.value === item.request_type)
            const rqTypeObj = {
            request_type: rqType !== undefined ? rqType.text_en : '',
            request_type_bn: rqType !== undefined ? rqType.text_bn : ''
             }
               const data = {
                    org_name: orgList !== undefined ? orgList.text_en : '',
                    org_name_bn: orgList !== undefined ? orgList.text_bn : '',
                    number_of_visitor: item.no_of_visitor ? item.no_of_visitor : 1,
                    new_status: this.getStatus(item.status)
                }
                const timeSlotObj = {
                    time_slot: item.opening_time + '---' + item.closeing_time
                }

                return Object.assign({}, item, rqTypeObj, timeSlotObj, data)
            })
            return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('pp_visit_request.reviewed')
      } else if (status === 3) {
        return this.$t('budget.approve')
      } else if (status === 4) {
        return this.$t('pp_visit_request.visited')
      } else if (status === 5) {
        return this.$t('tour_management.reject')
      }
    }
  }
}
</script>
